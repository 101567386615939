import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { FormikProps, getIn } from 'formik';

import { CharityEvent } from 'api/charityEventAPI';

dayjs.extend(advancedFormat);
dayjs.extend(localizedFormat);

export const getValidationMessage = <T>(fieldName: string, formik: Partial<FormikProps<T>>) =>
    (getIn(formik.touched, fieldName) as boolean | undefined) &&
    (getIn(formik.errors, fieldName) as string | undefined);

export const PAST_EVENT_DATE_FORMAT = 'dddd, MMMM Do, YYYY';
export const FUTURE_EVENT_DATE_FORMAT = `${PAST_EVENT_DATE_FORMAT} LT`;
export const formatEventDate = (event: CharityEvent) =>
    dayjs(event.eventDate).format(
        dayjs().isAfter(event.eventDate) ? PAST_EVENT_DATE_FORMAT : FUTURE_EVENT_DATE_FORMAT,
    );

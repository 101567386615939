import { Grommet, ThemeType } from 'grommet';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { MainLayout } from 'components/MainLayout';
import { CausesSupported } from 'components/pages/CausesSupported';
import { Donate } from 'components/pages/Donate';
import { EventDetails } from 'components/pages/EventDetails';
import { EventRegistrationConfirmation } from 'components/pages/EventRegistrationConfirmation';
import { Events } from 'components/pages/Events';
import { Home } from 'components/pages/Home';
import { MoneyRaised } from 'components/pages/MoneyRaised';
import { OurStory } from 'components/pages/OurStory';
import { AppRoutes } from 'services/routes';

const GREEN = '#519C76';

const theme: ThemeType = {
    global: {
        colors: {
            brand: '#002245',
            gray: '#E6E9F0',
            gray2: '#C5C8D3',
            pink: '#D3636B',
            purple: '#9264EC',
            green: GREEN,
            focus: '#9264EC',
        },
        font: {
            family: 'Josefin Sans',
            size: '16px',
        },
    },
    menu: {
        icons: {
            color: GREEN,
        },
    },
};

export const App = () => (
    <Grommet theme={theme} themeMode="dark" full>
        <BrowserRouter basename="/">
            <Routes>
                <Route element={<MainLayout />}>
                    <Route path={AppRoutes.home} element={<Home />} />
                    <Route path={AppRoutes.ourStory} element={<OurStory />} />
                    <Route path={AppRoutes.events} element={<Events />} />
                    <Route path={AppRoutes.eventDetails()} element={<EventDetails />} />
                    <Route
                        path={AppRoutes.eventRegistrationConfirmation()}
                        element={<EventRegistrationConfirmation />}
                    />
                    <Route path={AppRoutes.moneyRaised} element={<MoneyRaised />} />
                    <Route path={AppRoutes.causesSupported} element={<CausesSupported />} />
                    <Route path={AppRoutes.donate} element={<Donate />} />
                </Route>
                <Route path="*" element={<Navigate replace to={AppRoutes.home} />} />
            </Routes>
        </BrowserRouter>
    </Grommet>
);

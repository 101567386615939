// API Base URLs
export const CHARITY_API_BASE_URL =
    'https://bc97ya8jvk.execute-api.us-east-1.amazonaws.com/prod/charity_events';
export const USERS_API_BASE_URL =
    'https://ch1mbe2um8.execute-api.us-east-1.amazonaws.com/prod/users';

// API Names
export const CHARITY_API_NAME = 'charityEventAPI';
export const USERS_API_NAME = 'usersAPI';

export const ShirtSizes = ['YS', 'YM', 'YL', 'YXL', 'S', 'M', 'L', 'XL', 'XXL', 'XXXL', 'XXXXL'];

export const PageName = {
    Events: 'Events',
    All: 'All',
} as const;

export type PageNameType = (typeof PageName)[keyof typeof PageName];

import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Box, Button, Paragraph, Spinner } from 'grommet';
import { ComponentProps, useEffect, useState } from 'react';

import { useHandleError } from 'services/errors';

interface CheckoutFormProps {
    returnUrl: string;
}

export const CheckoutForm = ({ returnUrl }: CheckoutFormProps) => {
    const stripe = useStripe();
    const elements = useElements();
    const handleError = useHandleError();

    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                if (stripe) {
                    const clientSecret = new URLSearchParams(window.location.search).get(
                        'payment_intent_client_secret',
                    );
                    if (clientSecret) {
                        const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecret);
                        switch (paymentIntent?.status) {
                            case 'succeeded':
                                setMessage('Payment succeeded!');
                                break;
                            case 'processing':
                                setMessage('Your payment is processing.');
                                break;
                            case 'requires_payment_method':
                                setMessage('Your payment was not successful, please try again.');
                                break;
                            default:
                                setMessage('Something went wrong.');
                                break;
                        }
                    }
                }
            } catch (err) {
                handleError('Failed to retrieve payment intent.', err as Error);
            }
        })();
    }, [stripe, handleError]);

    const handleSubmit: ComponentProps<'form'>['onSubmit'] = (e) => {
        (async () => {
            e.preventDefault();

            if (!stripe || !elements) {
                // Stripe.js has not yet loaded.
                // Make sure to disable form submission until Stripe.js has loaded.
                return;
            }

            setIsLoading(true);

            const { error } = await stripe.confirmPayment({
                elements,
                confirmParams: {
                    return_url: returnUrl,
                },
            });

            // This point will only be reached if there is an immediate error when
            // confirming the payment. Otherwise, your customer will be redirected to
            // your `return_url`. For some payment methods like iDEAL, your customer will
            // be redirected to an intermediate site first to authorize the payment, then
            // redirected to the `return_url`.
            if (error.type === 'card_error' || error.type === 'validation_error') {
                setMessage(error.message ?? 'Something went wrong.');
            } else {
                setMessage('An unexpected error occurred.');
            }

            setIsLoading(false);
        })();
    };

    return (
        <Box margin="medium">
            <form onSubmit={handleSubmit}>
                <PaymentElement id="payment-element" />
                {!isLoading ? (
                    <Button
                        type="submit"
                        primary
                        size="small"
                        margin={{ top: 'small' }}
                        label="Pay Now"
                        disabled={isLoading || !stripe || !elements}
                    />
                ) : (
                    <Spinner size="small" margin={{ top: 'small' }} />
                )}
                {message && <Paragraph>{message}</Paragraph>}
            </form>
        </Box>
    );
};

export const AppRoutes = {
    home: '/',
    about: '/about',
    impact: '/impact',
    ourStory: '/about/our-story',
    events: '/events',
    eventDetails: (eventId = ':eventId') => `/events/${eventId}`,
    eventRegistrationConfirmation: (eventId = ':eventId', email = ':email') =>
        `/events/${eventId}/registration-confirmation/${email}`,
    moneyRaised: '/impact/money-raised',
    causesSupported: '/impact/causes-supported',
    donate: '/donate',
};

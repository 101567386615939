import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import {
    Box,
    Meter,
    ResponsiveContext,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHeader,
    TableRow,
    Text,
} from 'grommet';
import { useContext } from 'react';

import { BannerImage } from 'components/shared/BannerImage';

dayjs.extend(localizedFormat);

interface MoneyRaisedEntry {
    eventTitle: string;
    eventDate?: string;
    amount: number;
}

const MoneyRaisedData: MoneyRaisedEntry[] = [
    { eventTitle: 'Misc. donations Q1 2024', eventDate: undefined, amount: 271.0 },
    { eventTitle: 'Super Bowl Squares', eventDate: '02/14/2024', amount: 2799.61 },
    { eventTitle: 'Misc. donations Q3 2023', eventDate: undefined, amount: 2500 },
    { eventTitle: 'Golf Outing', eventDate: '07/21/2023', amount: 26017.51 },
    { eventTitle: 'Comedy Show', eventDate: '03/04/2023', amount: 8586.67 },
    { eventTitle: 'Misc. donations Q4 2022', eventDate: undefined, amount: 300 },
    { eventTitle: 'Bowloween', eventDate: '10/30/2022', amount: 2315.37 },
    { eventTitle: 'Golf Outing', eventDate: '07/22/2022', amount: 18711.06 },
    { eventTitle: 'Mustache Madness', eventDate: '03/01/2022', amount: 1100 },
];

const Columns: {
    label: string;
    property: keyof MoneyRaisedEntry;
    format?: (data: MoneyRaisedEntry) => string | undefined;
}[] = [
    { label: 'Event', property: 'eventTitle' },
    {
        label: 'Date',
        property: 'eventDate',
        format: (event: MoneyRaisedEntry) =>
            event.eventDate ? dayjs(event.eventDate).format('L') : undefined,
    },
    {
        label: 'Amount',
        property: 'amount',
        format: (event: MoneyRaisedEntry) =>
            event.amount?.toLocaleString(undefined, {
                style: 'currency',
                currency: 'USD',
            }),
    },
];

const meterValue = 100;

export const MoneyRaised = () => {
    const size = useContext(ResponsiveContext);
    const isMobile = size === 'small';
    return (
        <>
            <BannerImage />
            <Box alignSelf="center" width="xlarge" pad={{ top: 'small', bottom: 'medium' }}>
                <Box direction={!isMobile ? 'row' : 'column'} gap="medium">
                    <Box width="100%">
                        <Table>
                            <TableHeader>
                                <TableRow>
                                    {Columns.map((c) => (
                                        <TableCell
                                            key={c.property}
                                            scope="col"
                                            border={{ color: 'gray', side: 'bottom' }}
                                        >
                                            <Text weight="bold">{c.label}</Text>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {MoneyRaisedData.map((event, i) => (
                                    <TableRow key={event.eventTitle}>
                                        {Columns.map((c) => (
                                            <TableCell
                                                key={c.property}
                                                scope="col"
                                                border={
                                                    i !== MoneyRaisedData.length - 1
                                                        ? { color: 'gray', side: 'bottom' }
                                                        : undefined
                                                }
                                            >
                                                <Text>
                                                    {c.format?.(event) ?? event[c.property]}
                                                </Text>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Box>
                    <Box background="gray" pad="medium" width={!isMobile ? 'medium' : undefined}>
                        <Text size="xlarge" weight="bold" margin={{ bottom: 'medium' }}>
                            Children&apos;s Hospital Foundation Yearly Goal
                        </Text>
                        <Text size="medium">
                            Thank you for helping us reach our goal. We have pledged $20,000 per
                            year for five years, to help fund the cost of a research position at the
                            CHP Cancer Center.
                        </Text>
                        <Text
                            size="medium"
                            weight="bold"
                            margin={{ top: 'medium', bottom: 'small' }}
                        >
                            2024
                        </Text>
                        <Text size="xlarge" weight="bold">
                            $20,000 of $20,000
                        </Text>
                        <Box align="center">
                            <Stack anchor="center">
                                <Meter
                                    color="green"
                                    type="circle"
                                    thickness="large"
                                    background="gray2"
                                    max={100}
                                    value={meterValue}
                                    size="medium"
                                    margin={{ vertical: 'small' }}
                                />
                                <Box direction="row" align="center" pad={{ bottom: 'xsmall' }}>
                                    <Text size="4xl" weight="bold">
                                        {meterValue}
                                    </Text>
                                    <Text size="4xl">%</Text>
                                </Box>
                            </Stack>
                        </Box>
                        <Box width="100%">
                            <Table margin={{ top: 'medium' }}>
                                <TableHeader>
                                    <TableRow>
                                        <TableCell
                                            scope="col"
                                            border={{ color: 'gray2', side: 'bottom' }}
                                        >
                                            <Text weight="bold">Previous Year</Text>
                                        </TableCell>
                                        <TableCell
                                            scope="col"
                                            border={{ color: 'gray2', side: 'bottom' }}
                                        >
                                            <Text weight="bold">Donation</Text>
                                        </TableCell>
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                    <TableRow>
                                        <TableCell
                                            scope="col"
                                            border={{ color: 'gray2', side: 'bottom' }}
                                        >
                                            <Text>2023</Text>
                                        </TableCell>
                                        <TableCell
                                            scope="col"
                                            border={{ color: 'gray2', side: 'bottom' }}
                                        >
                                            <Text>$20,000</Text>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

import { Box, Footer, Main, Paragraph } from 'grommet';
import { Outlet } from 'react-router-dom';

import { ErrorContext } from 'components/ErrorContext';
import { Header } from 'components/Header';
import { Linkify } from 'components/shared/Linkify';

export const MainLayout = () => (
    <ErrorContext>
        <Main>
            <Header />
            <Box flex overflow="auto">
                <Box flex="grow">
                    <Outlet />
                </Box>
                <Footer background="brand" height="100px" pad="medium" justify="center">
                    <Paragraph fill size="small">
                        <Linkify content="The Chelsea McKita Memorial Foundation is a 501(c)(3) charitable organization. For information regarding how you or your company can help us support our causes, please contact us via email at, brian@thechelseamckitamemorialfoundation.org." />
                    </Paragraph>
                </Footer>
            </Box>
        </Main>
    </ErrorContext>
);

import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { Box, Heading } from 'grommet';
import { useParams } from 'react-router-dom';

import { useCharityEventDetails } from 'api/charityEventAPI';
import { EventRegistrationForm } from 'components/pages/EventRegistrationForm';
import { EventDescription } from 'components/shared/EventDescription';
import { EventImageGallery } from 'components/shared/EventImageGallery';

dayjs.extend(isBetween);

export const EventDetails = () => {
    const { eventId } = useParams();
    const event = useCharityEventDetails(eventId);
    return (
        <Box alignSelf="center" width="xlarge" pad={{ bottom: 'medium' }}>
            <Heading>{event?.eventTitle.toLocaleUpperCase()}</Heading>
            <EventDescription event={event} />
            <EventImageGallery event={event} />
            {event && dayjs().isBetween(event.registrationStart, event.registrationEnd) && (
                <EventRegistrationForm event={event} />
            )}
        </Box>
    );
};

import { Anchor, Box, Heading, Image, Paragraph } from 'grommet';

import DonateImg from 'assets/donate.jpg';
import PaypalQrCode from 'assets/PaypalQrCode.png';
import VenmoLogoBlue from 'assets/VenmoLogoBlue.png';
import VenmoQrCode from 'assets/VenmoQrCode.svg';

export const Donate = () => (
    <Box direction="row">
        <Box direction="column" pad="large">
            <Heading level={2}>DONATE</Heading>
            <Paragraph fill>
                Thank you for your generosity and for entrusting our foundation to support causes
                and organizations that impacted our family during Chelsea&apos;s 12 year battle. The
                support we provide is only possible because of people like you!
            </Paragraph>
            <Paragraph fill>
                If you are planning to donate $50 or more per month or $600 or more per year, please
                consider visiting our Get Involved tab and becoming a Foundation Partner!
            </Paragraph>
            <Box direction="row" gap="medium" pad={{ top: 'medium' }}>
                <Box direction="column" align="center">
                    <Box width={{ min: '80px', max: '150px' }}>
                        <Image
                            src="https://www.paypalobjects.com/webstatic/en_US/i/buttons/PP_logo_h_150x38.png"
                            alt="PayPal"
                            height="100%"
                            width="100%"
                            loading="lazy"
                        />
                        <Image
                            src={PaypalQrCode}
                            alt="Paypal QR Code"
                            height="100%"
                            width="100%"
                            loading="lazy"
                        />
                    </Box>
                    <Anchor
                        color="purple"
                        href="https://paypal.me/ChelseaMcKita?country.x=US&locale.x=en_US"
                        label="@ChelseaMcKita"
                        target="_blank"
                        rel="noreferrer"
                    />
                </Box>
                <Box direction="column" align="center">
                    <Box width={{ min: '80px', max: '150px' }}>
                        <Box pad={{ top: '10px' }}>
                            <Image
                                src={VenmoLogoBlue}
                                alt="Venmo"
                                height="100%"
                                width="100%"
                                loading="lazy"
                            />
                        </Box>
                        <Image
                            src={VenmoQrCode}
                            alt="Venmo QR Code"
                            height="100%"
                            width="100%"
                            loading="lazy"
                        />
                    </Box>
                    <Anchor
                        color="purple"
                        href="https://venmo.com/code?user_id=3454203892598010433&created=1679248937.528358&printed=1"
                        label="@thechelseamckitafoundation"
                        target="_blank"
                        rel="noreferrer"
                    />
                </Box>
            </Box>
            {/* <Box border="bottom" gap="medium" pad={{ bottom: 'medium' }}>
                <Text size="large">Your Gift</Text>
                <RadioButtonGroup
                    name="frequency"
                    options={[
                        { label: 'One-Time', value: 'ONE_TIME' },
                        { label: 'Monthly', value: 'MONTHLY' },
                    ]}
                    // value={value}
                    // onChange={(event) => setValue(event.target.value)}
                />
            </Box>
            <Box gap="medium">
                <RadioButtonGroup
                    name="frequency"
                    direction="row"
                    options={[
                        { label: '$25', value: 25 },
                        { label: '$50', value: 50 },
                        { label: '$75', value: 75 },
                        { label: '$150', value: 150 },
                        { label: '$250', value: 250 },
                        { label: 'Other', value: 0 },
                    ]}
                />
            </Box> */}
        </Box>
        <Box width="50%">
            <Image src={DonateImg} height="100%" width="100%" loading="lazy" />
        </Box>
    </Box>
);

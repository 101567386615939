import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { Avatar, Box, Heading, Text } from 'grommet';

import { useCharityEvents } from 'api/charityEventAPI';
import HomeBanner from 'assets/banners/homeBanner.jpg';
import Chelsea from 'assets/chelsea.jpg';
import { BannerImage } from 'components/shared/BannerImage';
import { EventsList } from 'components/shared/EventsList';

dayjs.extend(isSameOrBefore);

export const Home = () => {
    const events = useCharityEvents();
    return (
        <>
            <BannerImage src={HomeBanner} />
            <Box direction="column" align="center" background="gray" pad="medium">
                <Avatar size="3xl" src={Chelsea} />
                <Heading level={2}>OUR MISSION</Heading>
                <Box width="large">
                    <Text size="medium" textAlign="center">
                        The Chelsea McKita Memorial Foundation is a 501(c)(3) charitable
                        organization devoted to uplifting and inspiring children and young adults
                        with a (brain) cancer diagnosis. The foundation strives to contribute to the
                        health and well-being of young adults by providing financial support for
                        college expenses and/or social programs that align with Chelsea&apos;s
                        vision.
                    </Text>
                </Box>
            </Box>
            <Box direction="column" align="center">
                <Box direction="column" width="large">
                    <Heading level={2}>UPCOMING EVENTS</Heading>
                    <EventsList
                        events={events?.filter((event) => dayjs().isSameOrBefore(event.eventDate))}
                    />
                    <Heading level={2}>LAST EVENT</Heading>
                    <EventsList
                        events={events
                            ?.filter((event) => dayjs().isAfter(event.eventDate))
                            .slice(0, 1)}
                    />
                </Box>
            </Box>
        </>
    );
};

import { Amplify } from 'aws-amplify';
import { Text } from 'grommet';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';

import { App } from 'components/App';
import {
    CHARITY_API_BASE_URL,
    CHARITY_API_NAME,
    USERS_API_BASE_URL,
    USERS_API_NAME,
} from 'services/constants';

Amplify.configure({
    Auth: {
        region: 'us-east-1',
        userPoolId: 'us-east-1_iVttlESVu',
        userPoolWebClientId: '4paonm2uohud2olcj1afau6qjr',
        mandatorySignIn: false,
    },
    API: {
        endpoints: [
            {
                name: USERS_API_NAME,
                endpoint: USERS_API_BASE_URL,
            },
            {
                name: CHARITY_API_NAME,
                endpoint: CHARITY_API_BASE_URL,
            },
        ],
    },
    Analytics: {
        disabled: true, // Disabling for now to remove warnings
    },
});

const root = createRoot(document.getElementById('root')!);
root.render(
    <StrictMode>
        <ErrorBoundary
            fallbackRender={({ error }) => (
                <Text role="alert">
                    Something went wrong:
                    <pre style={{ color: 'red' }}>{(error as Error).message}</pre>
                </Text>
            )}
        >
            <App />
        </ErrorBoundary>
    </StrictMode>,
);

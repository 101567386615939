import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import isBetween from 'dayjs/plugin/isBetween';
import { Avatar, Box, Button, Spinner, Text } from 'grommet';
import { useNavigate } from 'react-router-dom';

import { CharityEvent } from 'api/charityEventAPI';
import { formatEventDate } from 'services/helpers';
import { AppRoutes } from 'services/routes';

dayjs.extend(advancedFormat);
dayjs.extend(isBetween);

interface EventsListProps {
    events?: CharityEvent[];
}

export const EventsList = ({ events }: EventsListProps) => {
    const navigate = useNavigate();
    return !events ? (
        <Spinner color="purple" size="medium" />
    ) : (
        <>
            {(events ?? []).map((event) => (
                <Box key={event.eventId} flex direction="row" pad={{ bottom: 'medium' }}>
                    <Avatar
                        size="large"
                        alignSelf="center"
                        src={event.image}
                        margin={{ right: 'small' }}
                    />
                    <Box direction="column">
                        <Text size="large" weight="bolder" style={{ textTransform: 'uppercase' }}>
                            {event.eventTitle}
                        </Text>
                        <Box direction="row" gap="xsmall" pad={{ bottom: 'xsmall' }}>
                            <Text size="medium">
                                {dayjs().isAfter(event.eventDate) && '(PAST EVENT) '}
                                {formatEventDate(event)}
                            </Text>
                            {event.registrationStart &&
                                dayjs().isBefore(event.registrationStart) && (
                                    <Text>{`(Registration starts ${dayjs(
                                        event.registrationStart,
                                    ).format('MMMM Do, YYYY')})`}</Text>
                                )}
                            {event.registrationEnd &&
                                dayjs().isBetween(event.registrationStart, event.eventDate) && (
                                    <Text>{`(Registration ends ${dayjs(
                                        event.registrationEnd,
                                    ).format('MMMM Do, YYYY')})`}</Text>
                                )}
                        </Box>
                        <Box direction="row" gap="medium">
                            {dayjs().isBetween(event.registrationStart, event.registrationEnd) ? (
                                <Button
                                    primary
                                    size="small"
                                    label="VIEW EVENT & REGISTER"
                                    onClick={() => navigate(AppRoutes.eventDetails(event.eventId))}
                                />
                            ) : (
                                <Button
                                    secondary
                                    size="small"
                                    label="VIEW EVENT & PHOTOS"
                                    onClick={() => navigate(AppRoutes.eventDetails(event.eventId))}
                                />
                            )}
                        </Box>
                    </Box>
                </Box>
            ))}
        </>
    );
};

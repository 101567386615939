import { Box, Heading, Paragraph } from 'grommet';
import { useParams } from 'react-router-dom';

import { useCharityEventDetails } from 'api/charityEventAPI';
import { EventDescription } from 'components/shared/EventDescription';

export const EventRegistrationConfirmation = () => {
    const { eventId, email } = useParams();
    const event = useCharityEventDetails(eventId);
    return (
        <Box alignSelf="center" width="xlarge" pad={{ bottom: 'medium' }}>
            <Heading level={2}>{`You are registered for the ${event?.eventTitle}!`}</Heading>
            <EventDescription event={event} />
            <Paragraph>Primary Contact: {email}</Paragraph>
            <Heading level={3}>Thank you for your support!</Heading>
        </Box>
    );
};

import { Box, Heading } from 'grommet';

import { useCharityEvents } from 'api/charityEventAPI';
import { BannerImage } from 'components/shared/BannerImage';
import { EventsList } from 'components/shared/EventsList';
import { PageName } from 'services/constants';

export const Events = () => {
    const events = useCharityEvents();
    return (
        <>
            <BannerImage pageName={PageName.Events} />
            <Box direction="column" align="center">
                <Box direction="column" width="large">
                    <Heading level={2}>EVENTS</Heading>
                    <EventsList events={events} />
                </Box>
            </Box>
        </>
    );
};

import { Box, Heading, Paragraph, Text } from 'grommet';

import { BannerImage } from 'components/shared/BannerImage';

export const OurStory = () => (
    <>
        <BannerImage />
        <Box alignSelf="center" width="xlarge" pad={{ bottom: 'medium' }}>
            <Heading level={2}>The Chelsea McKita Memorial Foundation Story</Heading>
            <Paragraph fill>
                We started this foundation in memory of our incredible daughter, Chelsea, with the
                mission to assist children and young adults with a similar health situation or with
                similar interests as her.
            </Paragraph>
            <Paragraph fill>
                Chelsea was in 7th grade; she loved all things Harry Potter and Pittsburgh Penguins.
                She loved to hang out with her friends and was one of the sweetest people you could
                meet. We realized that Chelsea had been having regular headaches, so she went to the
                pediatrician who suggested a CT scan to rule out any possible issues. She had that
                scan done June 7, 2007 and was admitted to Children&apos;s Hospital Pittsburgh (CHP)
                that afternoon with a golf ball sized mass in the base of her brain. The next day,
                she had full craniotomy brain surgery, which led to her cancer diagnosis,
                Medulloblastoma, June 13.
            </Paragraph>
            <Paragraph fill>
                This energetic, full-of-life, 13-year-old had to learn to walk, talk, and care for
                herself again in the months and years to follow. She crushed her OT, PT, and Speech
                Therapies! What was to be a two-three week stay at CHP post-surgery was one, and
                then she spent two weeks (half the expected time) and The Children&apos;s Institute
                of Pittsburgh, doing intensive inpatient rehabilitation. During this time, she
                learned of her diagnosis. She was determined to beat it! Her therapies continued
                outpatient for almost a year. The summer of 2007 was spent doing 6 weeks of daily
                radiation treatments, then a 6 week break before starting 6 months of chemotherapy.
            </Paragraph>
            <Paragraph fill>
                Chelsea was home schooled in 8th grade by her 7th grade teachers and went back to
                school part-time her 9th grade year. Though she looked like everyone else on the
                outside (a bit skinnier and with really short hair), the noise and business of a
                junior high school was overwhelming, and she would come home exhausted every day to
                all of the coursework for the honors classes she was in. Chelsea went back to school
                full-time in 10th grade. She was so happy to be back with friends and living a
                “normal” teenage life. By 11th grade, she was nearly back to her old self. She was
                involved in multiple clubs at school (Spanish Club, Hands for Service), was part of
                the choir, had a great group of friends and got to go to work! She graduated from
                North Hills Senior High School June 8, 2012. How fitting a date. (Exactly 5 years
                after her brain surgery.) It was quite an emotional day all around.
            </Paragraph>
            <Paragraph fill>
                She started college at Indiana University of Pennsylvania (IUP) August 2012, where
                she majored in Interior Design and minored in Theater Design. We were terrified of
                leaving her at school, but she quickly proved she was more than ready to “fly the
                coop”. She was doing great at college: she was involved in the student activity
                groups on campus and was meeting some great students and professors. Then in August
                of 2014, right before she was to return for her junior year, a scheduled yearly MRI
                showed the cancer had returned. This time, she had a large tumor that spanned her
                frontal lobe and had another at the base of her spine. She was given a 20% chance of
                survival. Her initial reaction was, “20% is not 0%.” We liked the way she was
                thinking! She did 12 months of chemotherapy and that shrunk the frontal lobe tumor
                enough to allow for radiation again. She had 28 treatments this time around, that
                ended October 6, 2015, when we were given the best news ever…She had beaten cancer
                TWICE!!!
            </Paragraph>
            <Paragraph fill>
                Though she was not ready to return to college, she took on-line classes until she
                returned to campus August 2016 with only 3 semesters to go. Due to the tumor and the
                58 collective radiation treatments, Chelsea&apos;s eyesight was diminishing, but
                this did not stop her from getting onto the Dean&apos;s List that first year back!
            </Paragraph>
            <Paragraph fill>
                June of 2017, Chelsea was living life and planning for her future, when we were told
                that her doctors suspected the cancer was back again. She was on a maintenance chemo
                at this point, so we stopped it and in August the recurrence was confirmed. At that
                time, there were no viable options to treat her, so she began a “manageable” regimen
                that allowed her to finish her classes at home. Chelsea graduated from IUP with her
                bachelor&apos;s degree December 17, 2017!!!
            </Paragraph>
            <Paragraph fill>
                After graduation, Chelsea was looking forward to moving on with her life, and living
                it to the fullest. She was looking for jobs and spending quality time with her best
                friends. February 2018 was a particularly great month for Chelsea. Brian&apos;s aunt
                and uncle took us to Hawaii, and she got to experience Pearl Harbor. Chelsea was a
                huge history buff, so this was an exceptional day, and we were more than happy to be
                along for the ride! About a week after we returned home, she got the best gift ever:
                a 5-pound cutie she named Stan. He is a great dog and Chelsea absolutely adored him!
            </Paragraph>
            <Paragraph fill>
                Through a series of events, Chelsea landed at the National Institutes of Health
                (NIH) in Bethesda, MD the spring of 2018 and was welcomed into an immunotherapy
                trial there. After 2 months, an MRI revealed that the was trial was unsuccessful.
                Two weeks later, the NIH did brain biopsy surgery, and the results of their testing
                led her to a trial at St. Jude Children&apos;s Research Hospital in Memphis, TN. The
                3 of us left July 31, 2018, and we lived there until February of 2019. (Brian lived
                part-time in Pittsburgh and Part-time in Memphis.) It was very difficult for Chelsea
                and Heather to be away from friends and family for so long, but they did get some
                visitors while there. When we moved back home, we traveled to St. Jude every 2 weeks
                for a few days, until May when we were told that though Chelsea had no new tumors,
                all the ones she had, had grown “significantly” since her March scans. We were being
                sent back to Pittsburgh to set up palliative (end of life) care. We were devastated.
            </Paragraph>
            <Paragraph fill>
                When we got home, we made sure Chelsea spent all the quality time she could with
                friends and family, until she passed away July 20, 2019, at the age of 25.
            </Paragraph>
            <Heading level={3}>So why a foundation?</Heading>
            <Paragraph fill>
                Chelsea&apos;s story has lots of rough moments, but there were so many great moments
                too. Our little family has been blessed with so many amazing people to support us.
                Over the years, there were multiple fundraisers to help us out, and Chelsea was
                gifted a beautiful bedroom makeover compliments of Make-a-Wish, donations, and an
                incredible friend and contractor. We attended multiple sporting events and she got
                to meet Sidney Crosby and Mario Lemieux surrounded by her closest friends. We went
                on lots of trips; from Disney, to Gatlinburg, to Waco (she went to school for
                interior design, so we had to visit Chip and Jo&apos;s silos), to Hawaii, to Paris
                and London. (We have VERY generous people in our lives!)
            </Paragraph>
            <Paragraph fill>
                Then there&apos;s Chelsea and her ability to go through all of this during such
                formidable years in her life with grace and strength like we&apos;d never seen. She
                had rough days, plenty of them, but we never heard “Why me?” or “I can&apos;t.”. In
                fact, when we realized there were no more treatment options, her biggest concern was
                how everyone else was going to deal with “this”. Honestly…She was 25 years old,
                signing a DNR and was genuinely concerned for everyone else.
            </Paragraph>
            <Paragraph fill>
                Thanks to the infectious positivity of a friend she met through CHP, Chelsea was
                always forward thinking. She never stopped dreaming of her future. He passed away
                before Chelsea, and we are sure they are together now.
            </Paragraph>
            <Paragraph fill>
                Chelsea was a giver. She was always thinking about the “next kid” and what she could
                do to help it be better for them. She completed many very personal surveys, and
                donated extra of whatever she had to offer (blood, spinal fluid, tissue samples,
                etc.) Through the countless needle sticks, sick days, and so many tests, she kept
                her eye on the prize. She did what she was asked/told and did it without complaint
                99% of the time.
            </Paragraph>
            <Paragraph fill>
                Chelsea registered to be an organ donor, but as you can imagine, as the body shuts
                down after all the treatments she endured, this becomes impossible. When presented
                the opportunity, we made the decision to donate her brain to CHP for research, as we
                know this is what she would have wanted.
            </Paragraph>
            <Paragraph fill>
                Though it made her VERY uncomfortable to hear it, she really was an inspiration, and
                looking back, maybe it&apos;s not so surprising how well she handled all she went
                through. This foundation is our way to pay it forward in Chelsea&apos;s name and
                memory, and we appreciate your support!!
            </Paragraph>
            <Paragraph fill>
                As Chelsea always said, “If it&apos;s not a bad day, it&apos;s a good day.” We hold
                tightly to those words and do our best to live our lives in a way that would make
                her as proud of us as we are and always will be of her!
            </Paragraph>
            <Text weight="bold">All our love,</Text>
            <Text weight="bold">Brian and Heather McKita</Text>
        </Box>
    </>
);

import { useMemo } from 'react';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

import { CharityEvent } from 'api/charityEventAPI';

const importAll = (r: Record<string, string>) =>
    [...Object.values(r)].map((src) => ({
        original: src,
        thumbnail: src,
        originalHeight: 600,
    }));

const eventPicturesMap: Record<string, ReactImageGalleryItem[]> = {
    'Golf Outing': importAll(
        import.meta.glob('/src/assets/events/golf-outing-2022/*', {
            eager: true,
            as: 'url',
        }),
    ),
    '2023 Golf Outing': importAll(
        import.meta.glob('/src/assets/events/golf-outing-2023/*', {
            eager: true,
            as: 'url',
        }),
    ),
    'Bowloween Event': importAll(
        import.meta.glob('/src/assets/events/bowloween-2022/*', {
            eager: true,
            as: 'url',
        }),
    ),
    '2023 Bowloween': importAll(
        import.meta.glob('/src/assets/events/bowloween-2023/*', {
            eager: true,
            as: 'url',
        }),
    ),
    'Comedy Show': importAll(
        import.meta.glob('/src/assets/events/comedy-show/*', {
            eager: true,
            as: 'url',
        }),
    ),
    'Causes Supported': importAll(
        import.meta.glob('/src/assets/causes-supported/*', {
            eager: true,
            as: 'url',
        }),
    ),
};

interface EventImageGalleryProps {
    event?: Partial<CharityEvent>;
}

export const EventImageGallery = ({ event }: EventImageGalleryProps) => {
    const images = useMemo(() => {
        const imgArr = [...(eventPicturesMap[event?.eventTitle ?? ''] ?? [])];
        imgArr.sort(() => Math.random() - 0.5);
        return imgArr;
    }, [event?.eventTitle]);
    return images.length > 0 ? <ImageGallery items={images} lazyLoad /> : null;
};

import { Anchor } from 'grommet';
import LinkifyReact from 'linkify-react';

interface LinkifyProps {
    content: React.ReactNode;
}

export const Linkify = ({ content }: LinkifyProps) => (
    <LinkifyReact
        options={{
            render: (renderParams) => (
                <Anchor
                    href={renderParams.attributes.href as string}
                    label={renderParams.content}
                    target="_blank"
                    rel="noreferrer"
                />
            ),
        }}
    >
        {content}
    </LinkifyReact>
);

import { Box, Image } from 'grommet';
import { useMemo } from 'react';

import { PageNameType } from 'services/constants';

const BannerImages: Record<string, string[]> = {
    Events: Object.values(
        import.meta.glob('/src/assets/eventBanners/*', { eager: true, as: 'url' }),
    ),
    All: Object.values(import.meta.glob('/src/assets/banners/*', { eager: true, as: 'url' })),
};

const getRandomBannerImg = (pageName: PageNameType | undefined) => {
    const imgs = [...(pageName ? BannerImages[pageName] : BannerImages.All)];
    imgs.sort(() => Math.random() - 0.5);
    return imgs[0];
};

interface BannerImageProps {
    src?: string;
    pageName?: PageNameType;
}

export const BannerImage = ({ src, pageName }: BannerImageProps) => {
    const randomImg = useMemo(() => getRandomBannerImg(pageName), [pageName]);
    return (
        <Box overflow="hidden" height={{ max: 'medium' }}>
            <Image src={src ?? randomImg} width="100%" loading="lazy" />
        </Box>
    );
};

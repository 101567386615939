import { useCallback, useContext } from 'react';

import { CurrentErrorContext } from 'components/ErrorContext';

interface CustomError {
    response: { data?: { detail: string } };
}

export const useHandleError = () => {
    const [, setCurrentError] = useContext(CurrentErrorContext);
    const handleError = useCallback(
        (msg: string, err: Error | CustomError) => {
            const errMsg = (err as CustomError).response?.data?.detail ?? msg;
            // eslint-disable-next-line no-console
            console.error(msg, errMsg, err);
            setCurrentError(errMsg);
        },
        [setCurrentError],
    );
    return handleError;
};
